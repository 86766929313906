@font-face {
    font-family:'PlusJakartaSans-Bold';
    font-style:normal;
    font-weight:700;
    font-display:swap;
    src: url('https://jondevelopment.com/clientassets/thunderproxies.com/PlusJakartaSans-Bold-subset.woff') format('woff2');
}

.highlighted-text {
    color: #3399ff;
}

@font-face{font-family:'PlusJakartaSans-ExtraBold';font-style:normal;font-weight:800;font-display:swap;src:url('https://jondevelopment.com/clientassets/thunderproxies.com/PlusJakartaSans-ExtraBold-subset.woff2') format('woff2');}

.feature-box {
    background: #0A1929;
    border: 1px solid rgb(19 47 76);
    padding: 2vh;
    text-align: left;
    border-radius: 10px;
}

.scene-container {
    position: inherti!important;
    width: 750px;
    height: 750px;
    margin-top: -50px;
}

iframe {
    border-radius: 5px;
    width: 302px;
    height: 76px;
}

.loading-icon-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: max-content; 
    height: 70vh;
}

//media query for mobile
@media screen and (max-width: 1200px) {
    .scene-container {
        display: none;
    }
}